@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');

*{
    font-family: 'Inter', sans-serif;
    margin: 0;
    padding: 0;
    
}
body{
    
    overflow-x: hidden;
}

::-webkit-scrollbar {
    width: 6px;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 5px;
  }
  
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #2F479E;
    border-radius: 5px;
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #2F479E;
  }

  .banner-bg{
    background-image: url('../public/assets/background_1.webp');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat; 

  }
  .newslette-bg{
    background-image: url('../public/assets/background3.webp');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat; 

  }
  .banner-bg2{
    background-image: url('../public/assets/background.webp');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat; 

  }
  .servicesSection-bg{
    background-image: url('../public/assets/service-bg.jpg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat; 

  }
  .about-bg{
    background-image: url('../public/assets/backgroundAboutPage.webp');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat; 

  }
  @media (max-width: 640px) {
    .about-bg {
      background-image: url('../public/assets/backgroundAboutPage2.png');
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
    }
  }
  .service-bg{
    background-image: url('../public/assets/servicePageBG.jpg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat; 

  }
  @media (max-width: 640px) {
    .service-bg {
      background-image: url('../public/assets/servicePageBG2.png');
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
    }
  }
  .blog-bg{
    background-image: url('../public/assets/blogHeader.png');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat; 

  }
  @media (max-width: 640px) {
    .blog-bg {
      background-image: url('../public/assets/blogHeader2.png');
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
    }
  }
  .contact-bg{
    background-image: url('../public/assets/contactPageBG.webp');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat; 

  }
  @media (max-width: 640px) {
    .contact-bg {
      background-image: url('../public/assets/contactPageBg2.png');
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
    }
  }
  .backgroundsolutionSection{
    background-image: url('../public/assets/backgroundsolutionSection.webp');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat; 
  }
  .cardBG{
    background-image: url('../public/assets/cardBg.webp');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat; 
  }

  img {
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */
  }

  .serviceHeader{
    background-image: url('../public/assets/serviceHeader.png');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat; 
  }
  .blogHeader{
    background-image: url('../public/assets/blogHeader.png');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat; 
  }


  .LinkItem{
    position: relative;
  }
  .active{
    font-weight: 800 !important;; 
  }
  .LinkItem::before{
    content: "";
    position: absolute;
    display: block;
    width: 100%;
    height: 2px;
    bottom: -2px;
    left: 0;
    background-color: #2E75B5;
    transform: scaleX(0);
    transition: transform 0.3s ease
  }
  .LinkItem:hover::before{
    transform: scaleX(1);
  }

  
  
  